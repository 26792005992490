import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cdn: 'https://cloud.vevanta.ru',
    filter: {
      search_string: "",
      id: null,
      action_id: 1,
      class_id: 1,
      type_flat_id: [],
      type_room_id: [],
      type_area_id: [],
      type_house_id: [],
      type_garage_id: [],
      type_commerce_id: [],
      microdistricts: [],
      village_id: [],
      tracts: [],
      house_wall_id: [],
      elevator: [],
      complexes: [],
      repairs_id: [],
      bathroom: [],
      balcons: [],
      windows: [],
      built_year: {
        min: null,
        max: null
      },
      distance_city: null,
      floors: null,
      agent_id: [],
      houses: [],
      price: {
        min: null,
        max: null
      },
      price_per_unit: {
        min: null,
        max: null
      },
      square: {
        min: null,
        max: null
      },
      square_kitchen: {
        min: null,
        max: null
      },
      land_area: {
        min: null,
        max: null
      },
      floor: {
        min: null,
        max: null
      },
      ceiling_height: {
        min: null,
        max: null
      },
      address: {
        city: null,
        settlement: null,
        street: null,
        tract: null,
        house: null,
        block_type: null,
        block: null,
      },
      exclusive: false,
      quickly: false,
      exchange: false,
      low_price: false,
    },
    order_by: {
      field: 'id',
      dir: 'DESC',
    },
    statusItems: 0,
    items: [],
    page: 1,
    items_count: 0,
    items_count_pages: 0,
    catalog: {
      microdistricts: [],
      tracts: [],
      complexes: [],
      agent_id: [],
      bathroom: [],
      classes: [],
      type_flat_id: [],
      type_room_id: [],
      type_house_id: [],
      type_commerce_id: [],
      type_garage_id: [],
      type_area_id: [],
      house_wall: [],
      repairs_id: [],
    }
  },

  mutations: {

    SET_CATALOG(state, catalog) {
      state.catalog = catalog
    },

    UPDATE_FILTER_VALUE(state, settings) {
      state.filter[settings[0]] = settings[1];
    },

    SET_FILTER(state, payload) {
      state.filter = payload
    },

    RESET_FILTER(state) {
      state.filter = {
        search_string: "",
        action_id: 1,
        class_id: state.filter.class_id,
        type_flat_id: [],
        type_room_id: [],
        type_area_id: [],
        type_house_id: [],
        type_garage_id: [],
        type_commerce_id: [],
        microdistricts: [],
        village_id: [],
        tracts: [],
        house_wall_id: [],
        complexes: [],
        repairs_id: [],
        windows: [],
        bathroom: [],
        balcons: [],
        elevator: [],
        built_year: {
          min: null,
          max: null
        },
        distance_city: null,
        floors: null,
        agent_id: [],
        houses: [],
        price: {
          min: null,
          max: null
        },
        price_per_unit: {
          min: null,
          max: null
        },
        square: {
          min: null,
          max: null
        },
        square_kitchen: {
          min: null,
          max: null
        },
        land_area: {
          min: null,
          max: null
        },
        floor: {
          min: null,
          max: null
        },
        ceiling_height: {
          min: null,
          max: null
        },
        address: {
          city: null,
          settlement: null,
          street: null,
          tract: null,
          house: null,
          block_type: null,
          block: null,
        },
        exclusive: false,
        quickly: false,
        exchange: false,
        low_price: false,
        id: null
      }
    },
    RESET_TYPE_OBJECT_FILTER(state) {
      state.filter.type_flat_id = [];
      state.filter.type_room_id = [];
      state.filter.type_area_id = [];
      state.filter.type_house_id = [];
      state.filter.type_garage_id = [];
      state.filter.type_commerce_id = [];
    },
    SET_PAGE(state, payload) {
      state.page = payload
    },
    SET_DATA_ITEMS(state, payload) {
      state.items = payload
    },
    SET_ITEMS_COUNT(state, payload) {
      state.items_count = payload
    },
    SET_ITEMS_COUNT_PAGE(state, payload) {
      state.items_count_pages = payload
    },
    SET_STATUS_ITEMS(state, status) {
      state.statusItems = status
    }
  },

  actions: {
    GET_DATA: async ({
      commit,
      state
    }) => {
      let {
        data
      } = await Axios.post('https://vevanta.ru/api/objects/get/' + state.page + '/', {
        filter: state.filter,
        order_by: state.order_by
      })
      if (data.items.length) {
        commit('SET_STATUS_ITEMS', 1)
      } else {
        commit('SET_STATUS_ITEMS', 2)
      }

      commit('SET_DATA_ITEMS', data.items)
      commit('SET_ITEMS_COUNT', data.count)
      commit('SET_ITEMS_COUNT_PAGE', data.count_pages)
    },

    GET_COUNT: async ({state, commit}) => {
      let count = await Axios.post('https://vevanta.ru/api/objects/get_count/', {
        filter: state.filter
      });
      commit('SET_ITEMS_COUNT', count.data)
    },

    GET_CATALOG: async ({
      commit,
      state
    }) => {
      let api = Axios.create({
        baseURL: 'https://vevanta.ru/api/'
      })

      let data = await api.post('https://vevanta.ru/api/catalog/get_catalog');
      commit('SET_CATALOG', data.data);
    }

  },

  getters: {
    getCatalog(state) {
      return state.catalog;
    },
    filter(state) {
      return state.filter
    }
  },

  modules: {}
})
