import Vue from 'vue'
import store from './store'
import axios from 'axios'

Vue.prototype.$axios = axios.create({
  baseURL: 'https://vevanta.ru/api/'
})

import widgets from './widgets/widget';

Vue.config.productionTip = false
let vm = new Vue({
  store,
  el: '#app',
  components: {
    ...widgets,
    'textOverflows': () => import('./components/TextOverflows'),
    'FavoriteButton': () => import('./components/FavoriteButton'),
    'FavoriteLink': () => import('./components/FavoriteLink'),
    'ObjectBadges': () => import('./components/ListingItems/ObjectBadges'),
    'IpotekaCalcStr': () => import('./components/IpotekaCalcStr'),
    'BankPartners': () => import('./components/BankPartners'),
    'HeroSimple': () => import('./components/HeroSimple'),
    'HeroBottom': () => import('./components/HeroBottom'),
    'MortgageList': () => import('./components/MortgageList'),
    'MortgagePrograms': () => import('./components/MortgagePrograms'),
    'MortgageSpec': () => import('./components/MortgageSpec'),
    'ImgTextBlock': () => import('./components/ImgTextBlock'),
    'HeroMiddle': () => import('./components/HeroMiddle'),
    'CardWithIcon': () => import('./components/CardWithIcon'),
    'NavLeft': () => import('./components/NavLeft'),
    'HeroTop': () => import('./components/HeroTop'),
    'NineCards': () => import('./components/NineCards'),
    'WorksAdv': () => import('./components/WorksAdv'),
    'VevantaVideoGallery': () => import('./components/VevantaVideoGallery'),
    'VevantaVideo': () => import('./components/VevantaVideo'),
    'Testimonials': () => import('./components/Testimonials'),
    'FiveCards': () => import('./components/FiveCards'),
    'QuizCountryHouseParameters': () => import('./components/QuizCountryHouseParameters'),
    'DeveloperMap': () => import('./components/DeveloperMap'),
    'FilterItems': () => import('./components/ListingItems/FilterItems'),
    'OrderQuiz': () => import('./components/orderQuiz'),
    'QuizApartmentParameters': () => import('./components/QuizApartmentParameters'),
    'BanksCards': () => import('./components/BanksCards'),
    'SearchFilterHome': () => import('./components/SearchFilterHome'),
    'ModalWorksAdv': () => import('./components/ModalWorksAdv'),
    'ModalPartners': () => import('./components/ModalPartners'),
    'IpotekaTop': () => import('./components/IpotekaTop'),
    'HowItWorks': () => import('./components/HowItWorks'),
    'ForWhat': () => import('./components/ForWhat'),
    'CardsPartners': () => import('./components/CardsPartners'),
    'CallBackConsultation': () => import('./components/CallBackConsultation'),
    'BottomForm': () => import('./components/BottomForm'),
    'FavoritePage': () => import('./components/favoritePage/Index.vue'),
    'ModalFeedback': () => import('./components/ModalFeedback'),
    'Feedback': () => import('./components/Feedback'),
    'AgentCallbackForm': () => import('./components/AgentCallbackForm'),
    'ObjectsByAgent': () => import('./components/ObjectsByAgent'),
    'HousesMap': () => import('./components/map/Objects/HousesMap'),
    'NewHousesMap': () => import('./components/map/newObjects/HousesMap'),
    'lifeHouses': () => import('./components/lifeHouses'),
    'CallBackHero': () => import('./components/CallBackHero'),
    'ObjectsByParameters': () => import('./components/ObjectsByParameters'),
    'ObjectHelper': () => import('./components/ObjectHelper'),
    'CalcHouse': () => import('./components/CalcHouse'),
    'nwTopList': () => import('./components/NewHouse/nwTopList.vue'),
    'NewHouseCard': () => import('./components/NewHouse/NewHouseCard.vue'),
    'NewHouseList': () => import('./components/NewHouse/List.vue'),
    'PartObjectsList': () => import('./components/NewHouse/PartObjectsList.vue'),
    'PlansNewHouse': () => import('./components/NewHouse/Plans.vue'),
    'Personal': () => import('./components/Personal'),
    'FormTwo': () => import('./components/FormTwo'),
    'FormPhone': () => import('./components/FormPhone'),
    'ModalSuccess': () => import('./components/ModalSuccess'),
    'ModalDefault': () => import('./components/ModalDefault'),
    'Consultation': () => import('./components/Consultation'),
    'SearchFilterMobile': () => import('./components/SearchFilterMobile'),
    'ManagerItem': () => import('./components/ManagerItem'),
    'ipotekaForm': () => import('./components/ipotekaForm'),
    'IpotekaCalc': () => import('./components/IpotekaCalc'),
    'Ipoteka': () => import('./components/Ipoteka'),
    'ButtonModal': () => import('./components/ButtonModal'),
    'ListingItems': () => import('./components/ListingItems/ListingItems'),
    // 'filterBadges': () => import('./components/filterBadges'),
    'ChatButton': () => import('./components/ChatButton'),
    'RatingButtons': () => import('./components/RatingButtons'),
    'ManagerCard': () => import('./components/ManagerCard'),
    'VideoWidget': () => import('./components/VideoWidget'),
    'BusTourBanner': () => import('./components/BusTourBanner'),
    'FeedbackButtons': () => import('./components/FeedbackButtons'),
    'Buyers': () => import('./components/Buyers/Index.vue'),
    'BanerTextCallback': () => import('./components/BanerTextCallback.vue'),
  }
});

document.addEventListener("DOMContentLoaded", () => {
  vm.$mount();
});
